import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Breadcrumbs from "../components/Breadcrumbs"
import Questions from "../components/Questions"
import InternalCards from "../components/InternalCards"
import Ready from "../components/Ready"
import Cta from "../components/Cta"

export const FaqPageTemplate = ({
  title,
  subtitle,
  questions,
  breadcrumbs,
}) => (
  <>
    <Header title={title} subtitle={subtitle}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
        <path d="M825.944 741.982c40.57 86.072 63.38 145.687 84.762 218.027 21.04 72.204 41.081 157.112-27.668 163.109-68.749 5.997-225.945-66.781-390.31-90.574-164.621-24.085-336.069.952-398.78-34.51-62.285-35.484-15.917-131.31-60.039-234.752-44.38-103.734-179.677-215.062-144.011-236.727 35.665-21.664 242.209 46.493 400.746 45.55 158.536-.94 268.641-70.96 353.03-49.638 84.133 21.03 142.04 133.58 182.27 219.515z" />
      </svg>
    </Header>
    <Breadcrumbs data={breadcrumbs} />
    <Questions data={questions} />
    <InternalCards locations services recycling />
    <Ready>
      <Cta href="https://app.skipthedepot.com/register">Get started</Cta>
    </Ready>
  </>
)

const FaqPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title, subtitle, seoDesc, questions },
    },
  },
  location,
}) => {
  const breadcrumbs = location.pathname.split("/")
  const [faqs, setFaqs] = useState(questions)

  const getData = async () => {
    try {
      const res = await fetch(
        "https://testytest.skipthedepot.com/api/faqs/en",
        // "http://localhost:3001/faqs/en",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      if (res.ok) {
        const json = await res.json()
        let arr = []
        Object.values(json.faqs).forEach((val) => {
          arr = arr.concat(val)
        })

        setFaqs({
          ...faqs,
          data: arr.map(({ q, a }) => ({ title: q, body: a })),
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Layout
      title={`FAQ | SkipTheDepot | Book A Pickup Today`}
      seoDesc={seoDesc}
    >
      <FaqPageTemplate
        title={title}
        subtitle={subtitle}
        questions={faqs}
        breadcrumbs={breadcrumbs}
      />
    </Layout>
  )
}

export default FaqPage

export const PageQuery = graphql`
  query faqQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "faq-page" } }) {
      frontmatter {
        title
        subtitle
        seoDesc
        formTitle
        questions {
          title
          subtitle
          data {
            title
            body
          }
        }
      }
    }
  }
`
